<template>
  <div class="material_container" v-loading="loading">
    <ul class="material_item">
      <li v-for="(item, index) in voteList" :key="index">
        <div class="vote_box">
            <h3>{{ item.title }}</h3>
            <div class="v_info">
                <span>0人参与投票</span>
                <span>投票时间：{{ item.voteStartDate }} ~ {{ item.voteEndDate }}</span>
            </div>
            <div class="v_option" v-for="(value, index) in Object.keys(item.optionArray)" :key="index">
                <div class="option_row">
                    <b>{{ value }}</b>
                    <span>{{ item.optionArray[value] }}票 {{ getProportion(item.optionArray[value], item.optionArray) }}</span>
                </div>
                <div class="option_bar">
                    <span :style="{ width: getProportion(item.optionArray[value], item.optionArray) }"> </span>
                </div>
            </div>
        </div>
        <div class="v_operations">
            <!-- <span @click="openAddVoteModal(item)">编辑</span> -->
            <span @click="del(item)">删除</span>
        </div>
      </li>
    </ul>
    <div class="pagination" v-show="total > 15">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { toPercent } from '@/utils/utils'
export default {
    data: function() {
        return {
            loading: false,
            pageSize: 15,
            pageNum: 1,
            total: 0,
            voteList: [], //互动视频列表
            check: [],
        }
    },
    created() {
        this.getVoteList()
    },
    methods: {
        // 投标占比
        getProportion(value, dataSource) {
            if (dataSource instanceof Object && Object.keys(dataSource).length > 0) {
                const numArr = Object.keys(dataSource).map((item) => {
                    return dataSource[item]
                })
                const reducer = (previousValue, currentValue) => previousValue + currentValue;
                const total = numArr.reduce(reducer)
                return toPercent(total > 0 ? value/total : 0, 0)
            }
        },
       //获取列表
        async getVoteList() {
            this.loading = true
            const params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            };
            try {
              const data = await this.API.materialCenter.voteInfoList(params);
              this.setCheck(data.data.list)
              this.voteList = data.data.list.map((item) => {
                return {
                  ...item,
                  optionArray: JSON.parse(item.optionArray)
                }
              });
              this.total = data.data.total;
              this.loading = false
            } catch (error) {
              this.loading = false
            }
        },
        // 分页
        handleCurrentChange(val) {
            this.pageNum = val
            this.getVoteList()
        },
        // pageSize切换
        handleSizeChange(val) {
            this.pageNum = 1
            this.pageSize = val
            this.getVoteList()
        },
        // 投票删除
        del(item) {
          this.$confirm("是否确认删除此投票?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(async () => {
            const params = {
              id: item.id
            };
            const data = await this.API.materialCenter.voteInfoDelete(params);
            if (data.code == 200) {
              // 当前处于最后一页，并且最后一页只剩下最后一条数据被删除的时候,需要将pageNumber减一
              if (Math.ceil((this.total - 1) / this.pageSize) === this.pageNum || this.pageNum === 1) {
                this.getVoteList()
              } else {
                this.pageNum -= 1
                this.getVoteList()
              }
            } else {
              this.$message.error("删除失败" + JSON.stringify(data));
            }
          });
        },
        setCheck(value) {
          value.forEach(() => {
            this.check.push('')
          });
        }
    },
}
</script>
<style lang="less" scoped>
// 名片
.material_container {
    .material_item {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0;
        li {
            height: 260px;
            width: 508px;
            border-radius: 6px;
            margin: 0 10px 16px;
            background: #F9F9FC;
            position: relative;
            .vote_box {
                padding: 12px 12px;
                > h3 {
                  margin-bottom: 8px;
                }
                .v_info {
                  font-size      : 12px;
                  color          : #8D8DA7;
                  display        : flex;
                  justify-content: space-between;
                  margin-bottom  : 16px;
                }
                .v_option {
                  margin-bottom: 10px;
              
                  .option_row {
                    display        : flex;
                    justify-content: space-between;
                    margin-bottom  : 5px;
                    align-items: center;
                    b {
                      font-weight: normal;
                      flex: 1;
                    }
              
                    span {
                      color    : #8D8DA7;
                      font-size: 12px;
                      width: 22%;
                      text-align: right;
                    }
                  }
              
                  .option_bar {
                    height    : 12px;
                    background: #E2E2EA;
              
                    span {
                      height        : 12px;
                      display       : inline-block;
                      vertical-align: top;
                      width         : 0;
                      background    : #FEA644;
                    }
                  }
                }
            }
            .v_operations {
              // width: 100%;
              text-align: right;
              position: absolute;
              right: 0;
              bottom: 0;
              opacity: 0;
              padding: 7px 12px;
              span {
                height: 28px;
                line-height: 28px;
                cursor: pointer;
                margin-left: 14px;
                background: rgba(0, 0, 0, 0.32);
                color: #fff;
                padding: 2px 4px;
                border-radius: 4px;
              }
            }
            &:hover {
              .v_operations {
                opacity: 1;
              }
            }
        }
    }
    .pagination {
      display: flex;
      justify-content: center;
      margin-bottom: 8px;
    }
}
</style>