<template>
    <el-dialog
      :visible="true"
      :before-close="beforeClose"
      center
      width="360px"
      :show-close="false"
    >
        <video :src="videoSrc" controls autoplay width="360"/>
    </el-dialog>
</template>
<script>
export default {
    props: {
        videoSrc: {
            type: String,
            default: '',
        }
    },
    methods: {
        beforeClose() {
            this.$emit('clearVideoSrc')
        }
    },
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog__header {
   padding: 0;
}
/deep/ .el-dialog__body {
    padding: 0;
    max-height: 320px;
}
</style>

