<template>
  <div class="rename" @click="beginReName">
    <span v-if="!visible" :title="value">
      {{ value }}
    </span>
    <el-input v-if="visible" ref="input" v-model="newValue" @blur="endReName" size="mini"></el-input>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    item: {
        type: Object,
        default: function() {
            return {}
        }
    },
  },
  data: function() {
    return {
      visible: false,
      newValue: '',
    }
  },
  methods: {
    initValue() {
      this.visible = true
      this.newValue = this.value
    },
   async beginReName() {
     await this.initValue()
     await this.$refs.input.focus()
   },
   clearData() {
      Object.assign(this.$data, this.$options.data())
    },
   async endReName() {
     if (this.newValue !== this.value) {
        const params = {
            id: this.item.id,
            videoName: this.newValue || ''
        };
        const data = await this.API.materialCenter.branchVideoUpdate(params);
        this.visible = false
        if (data.code != 200) {
            this.$message.error("修改失败" + JSON.stringify(data));
            return
        }
        this.$emit('updataList')
     } else {
         this.visible = false
     }
   }
  }
}
</script>
<style scoped lang="less">
.rename {
  height: 30px;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
