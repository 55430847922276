<template>
  <div class="material_container" v-loading="loading">
    <ul class="material_item">
      <li v-for="(item, index) in signupList" :key="index">
        <div class="signup_box">
            <h3>{{ item.title }}</h3>
            <div>
              <span>0人报名</span>
              <span>报名时间：{{ item.activityDate }} ~ {{ item.endDate }}</span>
            </div>
            <el-input v-if="item.nameStatus === '1'" placeholder="请输入姓名"></el-input>
            <el-input v-if="item.phoneStatus === '1'" placeholder="请输入手机号"></el-input>
            <!-- <el-input v-if="item.remarkStatus === '1'" type="textarea" rows="2" placeholder="请输入备注"></el-input> -->
            <el-button type="primary">{{item.buttonText || '立即报名'}}</el-button>
        </div>
        <div class="v_operations">
            <span @click="del(item)">删除</span>
        </div>
      </li>
    </ul>
    <div class="pagination" v-show="total > 15">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
    data: function() {
        return {
            loading: false,
            pageSize: 15,
            pageNum: 1,
            total: 0,
            signupList: [], //互动视频列表
        }
    },
    created() {
        this.getSignupList()
    },
    methods: {
       // 获取报名列表
        async getSignupList() {
            this.loading = true
            const params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            };
            try {
              const data = await this.API.materialCenter.signUpInfoList(params);
              this.signupList = data.data.list;
              this.total = data.data.total;
              this.loading = false
            } catch (error) {
              this.loading = false
            }
        },
        // 分页
        handleCurrentChange(val) {
            this.pageNum = val
            this.getSignupList()
        },
        // pageSize切换
        handleSizeChange(val) {
            this.pageNum = 1
            this.pageSize = val
            this.getSignupList()
        },
        // 报名-删除
        del(item) {
          this.$confirm("是否确认删除此报名?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(async () => {
            const params = {
              id: item.id
            };
            const data = await this.API.materialCenter.signUpInfoDelete(params);
            if (data.code == 200) {
              // 当前处于最后一页，并且最后一页只剩下最后一条数据被删除的时候,需要将pageNumber减一
              if (Math.ceil((this.total - 1) / this.pageSize) === this.pageNum || this.pageNum === 1) {
                this.getSignupList()
              } else {
                this.pageNum -= 1
                this.getSignupList()
              }
            } else {
              this.$message.error("删除失败" + JSON.stringify(data));
            }
          });
        },
    },
}
</script>
<style lang="less" scoped>
// 名片
.material_container {
    .material_item {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0;
        li {
            height: 222px;
            width: 508px;
            border-radius: 6px;
            margin: 0 10px 16px;
            background: #F9F9FC;
            position: relative;
            .signup_box {
              padding: 12px 12px;
              > h3 {
                margin-bottom: 8px;
              }
              > div {
                display: flex;
                align-items: center;
                margin-bottom: 12px;
                > span {
                  font-size: 12px;
                  color: #8D8DA7;
                  &:first-child {
                    margin-right: 48px;
                  }
                }
              }

            }
            .v_operations {
              text-align: right;
              position: absolute;
              right: 0;
              bottom: 0;
              opacity: 0;
              padding: 7px 12px;
              span {
                height: 28px;
                line-height: 28px;
                cursor: pointer;
                margin-left: 14px;
                background: rgba(0, 0, 0, 0.32);
                color: #fff;
                padding: 2px 4px;
                border-radius: 4px;
              }
            }
            &:hover {
              .v_operations {
                opacity: 1;
              }
            }
        }
    }
    .pagination {
      display: flex;
      justify-content: center;
      margin-bottom: 8px;
    }
}
</style>