<template>
    <div class="editCard">
        <h3>基本信息</h3>
        <el-form :model="cardInfo" ref="cardInfo" label-width="76px" class="card_info_form">
            <el-form-item label="姓名："  prop="name" :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' }]">
                <el-input v-model="cardInfo.name" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="公司：" prop="company" :rules="[{ required: true, message: '请输入公司名称', trigger: 'blur' }]">
                <el-input v-model="cardInfo.company" placeholder="请输入公司名称"></el-input>
            </el-form-item>
            <el-form-item label="职位：" prop="position" :rules="[{ required: true, message: '请输入职位', trigger: 'blur' }]">
                <el-input v-model="cardInfo.position" placeholder="请输入职位"></el-input>
            </el-form-item>
            <el-form-item label="电话：" prop="telephone" :rules="[{ required: true, message: '请输入手机号', trigger: 'blur' }]">
                <el-input v-model="cardInfo.telephone" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="头像：" :rules="[{ required: true }]">
                <div class="upload_samll delete">
                    <div :style="{ borderColor: checkHeadUrl ? '#FA5555' : ''}" @click="importWord('headUrl')">
                        <img :src="cardInfo.headUrl || '../../../../../../assets/images/image_add.svg'" alt="" :style="{ width: cardInfo.headUrl ? '' : '36px', height: cardInfo.headUrl ? '' : '36px' }">
                        <span v-show="cardInfo.headUrl" @click.stop="">
                            <i class="el-icon-delete" @click="deleteImg('headUrl')"></i>
                        </span>
                    </div>
                    <!-- <span @click="importWord('headUrl')">重新上传</span> -->
                </div>
                <span style="color: #FA5555; position: absolute; fontSize: 12px; lineHeight: 1; margin-top: 4px" v-show="checkHeadUrl">请上传头像</span>
            </el-form-item>
        </el-form>
        <h3>微信二维码</h3>
        <div class="upload_samll delete">
            <div style="width: 188px; height: 188px" @click="importWord('wxQrCode')">
                <img :src="cardInfo.wxQrCode || '../../../../../../assets/images/image_add.svg'" alt="" :style="{ width: cardInfo.wxQrCode ? '' : '36px', height: cardInfo.wxQrCode ? '' : '36px' }">
                <span v-show="cardInfo.wxQrCode" @click.stop="">
                    <i class="el-icon-delete" @click="deleteImg('wxQrCode')"></i>
                </span>
            </div>
            <!-- <span @click="importWord('wxQrCode')">重新上传</span> -->
        </div>
        <input type="file" ref="fileInput" style="display: none" @change="batchImport" accept=".jpeg,.jpg,.png,.gif">
        <el-button @click="cardConfirm" type="primary" :disabled="userInfo.remainTime === 0 && userInfo.whiteStatus ? true : false">确 定</el-button>
        <CropperDialog ref="cropper" @receiveData="setUploadUrl" />
    </div>
</template>
<script>
import CropperDialog from '@/components/cropperDialog'
export default {
    data: function() {
        return {
            upload_txt: '',
            defaultValue: null,
            checkHeadUrl: false,
            cardInfo: {
                name: '',
                company: '',
                position: '',
                telephone: '',
                headUrl: '',
                wxQrCode: '',
            },
            userInfo: JSON.parse(localStorage.getItem('userInfo')) || {}, // 获取localStorage中的info
        }
    },
    components: {
        CropperDialog
    },
    inject: ['Instance'],
    methods: {
        show(value) {
            if (value && value instanceof Object && Object.keys(value).length > 0) {
               this.defaultValue = value
               this.cardInfo = {
                    name: value.name,
                    company: value.company,
                    position: value.position,
                    telephone: value.telephone,
                    headUrl: value.headUrl,
                    wxQrCode: value.wxQrCode,
               }
            }
        },
        // 关闭弹框
        beforeClose() {
            this.$refs.cardInfo.resetFields();
            Object.assign(this.$data, this.$options.data())
        },
        cardConfirm() {
            const _that = this
            this.$refs.cardInfo.validate(async (valid) => {
                if (valid) {
                    if (!_that.cardInfo.headUrl) {
                        return _that.checkHeadUrl = true
                    }
                    if (!_that.defaultValue) {
                        let arr = []
                        let i = 0
                        if (_that.cardInfo.wxQrCode) {
                            arr.push(_that.API.materialCenter.businessCardCreate({
                                ..._that.cardInfo,
                                wxQrCode: undefined,
                            }).then((res) => {
                                 if (res && res instanceof Object && res.code === 200) {
                                     i++
                                 }
                            }))
                        }
                        arr.push(_that.API.materialCenter.businessCardCreate({
                            ..._that.cardInfo,
                        }).then((res) => {
                            if (res && res instanceof Object && res.code === 200) {
                                i++
                            }
                        }))
                        Promise.all(arr).then(() => {
                            if (i) {
                                _that.$message.success(`新增名片成功！`)
                                _that.beforeClose()
                                _that.Instance.$refs.cardList.getCardList()
                            } else {
                                 _that.$message.error(`新增名片失败！`)
                            }
                        })
                    } else {
                        _that.API.materialCenter.businessCardUpdate({
                            id: _that.defaultValue.id,
                            ..._that.cardInfo
                        }).then((res) => {
                            if (res && res instanceof Object && res.code === 200) {
                                _that.$message.success(`编辑名片成功！`)
                                _that.beforeClose()
                                _that.Instance.$refs.cardList.getCardList()
                            } else {
                                _that.$message.error(`编辑名片失败！`)
                            }
                        })
                    }
                } else {
                    if (!_that.cardInfo.headUrl) {
                        _that.checkHeadUrl = true
                    }
                }
            });
        },

        importWord(value) {
            this.upload_txt = value
            this.$refs.fileInput.click()
        },
        //图片上传前，检查大小是否超出
        imgBeforeUpload(file) {
            const isFileSizeFit = file.size / 1024 / 1024 < 5;
            if (!isFileSizeFit) {
                this.$message.error("图片大小不能超过 5MB!");
            }

            return isFileSizeFit;
        },
        batchImport(e) {
            const [file] = e.target.files;
            const checkSize = this.imgBeforeUpload(file)
            if (checkSize) {
                this.$refs.cropper.openDialog(file)
            }
             // 清空input的值
            this.$refs.fileInput.value = ''
        },
        setUploadUrl(url) {
            // 上传的是小头像
            if (this.upload_txt === 'headUrl') {
                this.cardInfo.headUrl = url
                this.checkHeadUrl = false
            }
            if (this.upload_txt === 'wxQrCode') {
                this.cardInfo.wxQrCode = url;
            }
        },
        deleteImg(value) {
            if (value === 'headUrl') {
                this.cardInfo.headUrl = '';
            }
            if (value === 'wxQrCode') {
                this.cardInfo.wxQrCode = '';
            }
        },
    }
}
</script>
<style lang="less" scoped>
.editCard {
    padding: 20px 20px 0 46px;
    width: 100%;
    > h3 {
        margin-bottom: 12px;
    }
    .upload_samll {
        display: flex;
        align-items: flex-end;
        > div {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f5f8ff;
            border: 1px solid #f5f8ff;
            box-sizing: content-box;
            height: 64px;
            width: 64px;
            cursor: pointer;
            > img {
                max-width: 100%;
                max-height: 100%;
            }
            > span {
                position: absolute;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(29, 29, 29, 0.7);
                opacity: 0;
                color: #fff;
                font-size: 22px;
                cursor: pointer;
                > i:hover {
                    color: #FF9226;
                }
            }
        }
        > span {
            margin-left: 16px;
            font-size: 12px;
            color: #3472E8;
            cursor: pointer;
            &:hover {
                color: #1a61e9;
            }
        }
    }
    .delete:hover {
        > div {
            > span {
                opacity: 1;
            }
        }
    }
    > button {
        margin-top: 24px;
        width: 320px;
    }
}
</style>