<template>
  <div class="material_container" v-loading="loading">
    <ul class="material_item">
      <li v-for="(item, index) in cardList" :key="index">
        <div class="card_box_1" v-if="item.wxQrCode">
          <div class="headerImg">
            <img :src="item.headUrl" alt="头像">
          </div>
          <h3>{{ item.name }}</h3>
          <span>{{ item.position }}</span>
          <div class="qrCodeImg">
            <img :src="item.wxQrCode" alt="">
          </div>
          <p>{{ item.company }}</p>
          <div class="iphone">
            <span>{{ item.telephone }}</span>
            <img src="../../../../../../assets/images/interact_video/ico_tel.png" alt="">
          </div>
        </div>
        <div class="card_box_2" v-else>
          <div class="headerImg">
            <img :src="item.headUrl" alt="头像">
          </div>
          <h3>{{ item.name }}</h3>
          <span>{{ item.position }}</span>
          <p>{{ item.company }}</p>
          <div class="iphone">
            <span>{{ item.telephone }}</span>
            <img src="../../../../../../assets/images/interact_video/ico_tel.png" alt="">
          </div>
        </div>
        <div class="v_operations">
            <span @click="openAddCardModal(item)">编辑</span>
            <span @click="del(item)">删除</span>
        </div>
      </li>
    </ul>
    <div class="pagination" v-show="total > 15">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
    data: function() {
        return {
            loading: false,
            pageSize: 15,
            pageNum: 1,
            total: 0,
            cardList: [], //互动视频列表
        }
    },
    inject: ['Instance'],
    created() {
        this.getCardList()
    },
    methods: {
        // 打开名片编辑/新建弹框
        openAddCardModal(value) {
          this.Instance.$refs.cardEdit.show(value)
        },
       //获取列表
        async getCardList() {
            this.loading = true
            const params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            };
            try {
              const data = await this.API.materialCenter.businessCardList(params);
              this.cardList = data.data.list;
              this.total = data.data.total;
              this.loading = false
            } catch (error) {
              this.loading = false
            }
        },
        // 分页
        handleCurrentChange(val) {
            this.pageNum = val
            this.getCardList()
        },
        // pageSize切换
        handleSizeChange(val) {
            this.pageNum = 1
            this.pageSize = val
            this.getCardList()
        },
        // 名片列表-删除
        del(item) {
          this.$confirm("是否确认删除此名片?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(async () => {
            const params = {
              id: item.id
            };
            const data = await this.API.materialCenter.businessCardDelete(params);
            if (data.code == 200) {
              // 当前处于最后一页，并且最后一页只剩下最后一条数据被删除的时候,需要将pageNumber减一
              if (Math.ceil((this.total - 1) / this.pageSize) === this.pageNum || this.pageNum === 1) {
                this.getCardList()
              } else {
                this.pageNum -= 1
                this.getCardList()
              }
            } else {
              this.$message.error("删除失败" + JSON.stringify(data));
            }
          });
        },
    },
}
</script>
<style lang="less" scoped>
.material_container {
    .material_item {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0;
        li {
            height: 292px;
            width: 252px;
            border-radius: 6px;
            margin: 0 10px 16px;
            background: #F5F6FD;
            box-shadow: 0px 6px 12px rgba(85, 87, 105, 0.16);
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .card_box_1 {
              width: 200px;
              height: 236px;
              padding: 0 12px 14px;
              background: #fff;
              border-radius: 6px;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-top: 22px;
              color: #252525;
              .headerImg {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background: #f5f8ff;
                border: 1px solid #252525;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: -24px;
                > img {
                  max-width: 100%;
                  max-height: 100%;
                }
              }
              > h3 {
                margin-top: 4px;
              }
              .qrCodeImg {
                width: 80px;
                height: 80px;
                overflow: hidden;
                background: red;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 10px 0 14px 0;
                > img {
                  max-width: 100%;
                  max-height: 100%;
                }
              }
              > p {
                align-self: flex-start;
                font-size: 12px;
                margin-bottom: 10px;
              }
              .iphone {
                align-self: flex-start;
                font-size: 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                img {
                  width: 24px;
                }
              }
            }
            .card_box_2 {
              width: 228px;
              height: 120px;
              position: relative;
              background: #4046E6;
              border-radius: 6px;
              padding: 12px;
              display: flex;
              flex-direction: column;
              color: #fff;
              .headerImg {
                position: absolute;
                top: 12px;
                right: 12px;
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background: #f5f8ff;
                border: 1px solid #252525;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                > img {
                  max-width: 100%;
                  max-height: 100%;
                }
              }
              > p {
                margin-top: 10px;
                font-size: 12px;
              }
              .iphone {
                align-self: flex-start;
                font-size: 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                img {
                  width: 24px;
                }
              }
            }
            .v_operations {
              width: 100%;
              text-align: right;
              position: absolute;
              left: 0;
              bottom: 0;
              opacity: 0;
              padding: 7px 12px;
              span {
                height: 28px;
                line-height: 28px;
                cursor: pointer;
                margin-left: 14px;
                background: rgba(0, 0, 0, 0.32);
                color: #fff;
                padding: 2px 4px;
                border-radius: 4px;
              }
            }
            &:hover {
              .v_operations {
                opacity: 1;
              }
            }
        }
    }
    .pagination {
      display: flex;
      justify-content: center;
      margin-bottom: 8px;
    }
}
</style>