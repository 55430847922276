<template>
  <div class="interact_page" v-loading="loading">
    <div class="operation">
      <el-button @click="del(checkList)" type="danger" size="small" v-show="visible" :disabled="!checkList.length">批量删除</el-button>
      <el-button @click="isEdit" type="primary" size="small">{{ visible ? '取消编辑' : '编辑'}}</el-button>
    </div>
    <el-checkbox-group v-model="checkList">
      <ul class="interact_image_list">
        <li :class="['li_add', userInfo.remainTime > 0 || !userInfo.whiteStatus ? '' : 'disabled']" @click="openUploadModal">
          <img src="@/assets/images/interact_video/ico_add.svg" alt="">
          <span>添加图片</span>
          <p>来添加你的图片吧</p>
        </li>
        <li v-for="(item, index) in imageList" :key="index">
          <div class="v_img">
            <div @click="checkBox(item)">
              <img :src="item.picUrl" alt="" />
            </div>
            <div class="v_operations">
              <span @click.stop="del(item)">删除</span>
            </div>
            <el-checkbox :label="item.id" v-show="visible">&nbsp</el-checkbox>
          </div>
          <div class="v_title">
            <EditName :value="item.picName" :item="item" @updataList="getImageList" />
          </div>
        </li>
      </ul>
    </el-checkbox-group>
    <div class="pagination" v-show="total > pageSize">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import EditName from './EditName.vue'
export default {
  data() {
    return {
      loading: false,
      pageSize: 23,
      pageNum: 1,
      total: 0,
      imageList: [], //互动视频列表
      visible: false,
      checkList: [], // 选中视频列表
      userInfo: JSON.parse(localStorage.getItem('userInfo')) || {}, // 获取localStorage中的info
    };
  },
  components: {
    EditName
  },
  created() {
    this.getImageList()
  },
  mounted() {
    // 接收批量上传接口上传成功后返回的值
    this.$bus.$on("uploadSuccess", async (from, data) => {
      if (from === 'material_image') {
        this.picMaterialCreate(data)
      }
    });
  },
  beforeDestroy () {
    this.$bus.$off('uploadSuccess');
  },
  methods: {
    checkBox(item) {
      // 当是编辑态的时候
      if (this.visible) {
        if (this.checkList.indexOf(item.id) === -1) {
          this.checkList.push(item.id)
        } else {
          this.checkList = this.checkList.filter(value => value !== item.id)
        }
      }
    },
    // 打开批量选中模式
    isEdit() {
      if (this.visible) {
        this.visible = false
        this.checkList = []
      } else {
        this.visible = true
      }
    },
      // 分页
    handleCurrentChange(val) {
        this.pageNum = val
        this.getImageList()
    },
    // 获取图片列表
    async getImageList() {
      this.loading = true
      const params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
      };
      try {
        const data = await this.API.materialCenter.picMaterialList(params);
        this.imageList = data.data.list;
        this.total = data.data.total;
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    // 上传图片
    openUploadModal() {
      if (this.userInfo.remainTime > 0 || !this.userInfo.whiteStatus) {
        this.$bus.$emit('openUploadFile', {from:'material_image',type:'image'})
      }
    },
    // 批量新增图片
    async picMaterialCreate(value) {
      this.loading = true
      const params = value.map((item) => {
        return {
          picName: item.fileName,
          picUrl: item.downUrl,
        }
      })
      const data = await this.API.materialCenter.picMaterialCreate(params);
      if (data && data instanceof Object && data.code === 200) {
        this.getImageList()
      }
    },
    // 图片删除
    del(item) {
      this.$confirm(`请确定是否${Array.isArray(item) ? '批量' : ''}删除图片?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        let params = []
        if (item && item instanceof Object) {
          params = [item.id]
        }
        if (item && item instanceof Array) {
          params = [...item]
        }
        const data = await this.API.materialCenter.picMaterialDelete(params);
        if (data.code == 200) {
          this.$message.success(`${Array.isArray(item) ? '批量' : ''}删除成功`);
          // 当前处于最后一页，并且最后一页只剩下最后一条数据被删除的时候,需要将pageNumber减一
          if (Math.ceil((this.total - 1) / this.pageSize) === this.pageNum || this.pageNum === 1) {
            this.getImageList()
          } else {
            this.pageNum -= 1
            this.getImageList()
          }
        } else {
          this.$message.error("删除失败" + JSON.stringify(data));
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
