<template>
    <div class="material_center_container">
        <header>
            <el-radio-group v-model="fileType" size="small">
                <el-radio-button label="video">分支视频</el-radio-button>
                <el-radio-button label="image">图片</el-radio-button>
                <el-radio-button label="card">名片</el-radio-button>
                <el-radio-button label="vote">投票</el-radio-button>
                <el-radio-button label="signup">报名</el-radio-button>
            </el-radio-group>
            <!-- <div class="header_right"></div> -->
        </header>
        <div class="content">
            <VideoList v-show="fileType === 'video'" />
            <ImageList v-show="fileType === 'image'" />
            <CardList v-show="fileType === 'card'" />
            <VoteList v-show="fileType === 'vote'" />
            <SignupList v-show="fileType === 'signup'" />
        </div>
    </div>
</template>
<script>
import VideoList from './pages/videoList/videoList.vue'
import ImageList from './pages/imageList/imageList.vue'
import CardList from './pages/cardList/index.vue'
import VoteList from './pages/voteList/index.vue'
import SignupList from './pages/signupList/index.vue'
export default {
    data: function() {
        return {
            fileType: 'video',
            keyword: '',
        }
    },
    components: {
        VideoList,
        ImageList,
        CardList,
        VoteList,
        SignupList
    },
    methods: {
        searchKeyword(val) {
            console.log(val)
        }
    },
}
</script>
<style lang="less" scoped>
@import "./index.less";
</style>