<template>
  <div class="interact_page" v-loading="loading">
    <div class="operation" v-show="activeTab === '本地视频'">
      <el-button @click="del(checkList)" type="danger" size="small" v-show="visible" :disabled="!checkList.length">批量删除</el-button>
      <el-button @click="isEdit" type="primary" size="small">{{ visible ? '取消编辑' : '编辑'}}</el-button>
    </div>
    <div class="filter">
      <el-tabs v-model="activeTab" @tab-click="handleTabClick">
        <el-tab-pane label="本地视频" name="本地视频"></el-tab-pane>
        <!-- <el-tab-pane label="视频素材" name="视频素材"></el-tab-pane> -->
        <el-tab-pane label="推荐视频" name="推荐视频"></el-tab-pane>
        <el-tab-pane label="行情回顾" name="行情回顾"></el-tab-pane>
        <el-tab-pane label="财务报告" name="财务报告"></el-tab-pane>
        <el-tab-pane label="舆情日报" name="舆情日报"></el-tab-pane>
        <el-tab-pane label="个股诊断" name="个股诊断"></el-tab-pane>
        <el-tab-pane label="产业链" name="产业链"></el-tab-pane>
      </el-tabs>
      <div class="inputSearch" v-if="['本地视频', '推荐视频', '产业链'].indexOf(activeTab) !== -1">
        <el-input placeholder="输入要查找的内容" v-model="keyword" @change="searchKeyword" clearable>
        <!-- <el-button slot="append" icon="el-icon-search"></el-button> -->
        </el-input>
      </div>
      <el-select
        v-if="['行情回顾', '财务报告', '舆情日报', '个股诊断'].indexOf(activeTab) !== -1"
        v-model="keyword"
        placeholder="输入要查找的内容"
        filterable
        remote
        clearable
        :remote-method="searchStock"
        :loading="selectLoading"
        @change="changeValue"
      >
        <el-option
            v-for="item in stockArr"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <el-checkbox-group v-model="checkList">
      <ul class="interact_video_list">
        <li
          :class="['li_add', userInfo.remainTime > 0 || !userInfo.whiteStatus ? '' : 'disabled']"
          @click="openUploadModal"
          v-show="activeTab === '本地视频'"
        >
          <img src="@/assets/images/interact_video/ico_add.svg" alt="">
          <span>添加视频</span>
          <p>建议视频比例 9:16</p>
        </li>
        <li v-for="(item, index) in videoList" :key="index">
          <div class="v_img">
            <img :src="item.picUrl" alt="" @click="visible ? checkBox(item) : checkVideo(item)" :class="item.jobStatus" />
            <div class="v_operations" v-show="activeTab === '本地视频'">
              <span @click="del(item)">删除</span>
            </div>
            <el-checkbox :label="item.id" v-show="visible">&nbsp</el-checkbox>
          </div>
          <div class="v_title">
            <EditName :value="item.videoName" :item="item" @updataList="getVideoList" v-if="activeTab === '本地视频'" />
            <span v-else>{{ item.videoName }}</span>
          </div>
        </li>
        <!-- <el-empty description="暂无数据" v-show="activeTab === '视频素材' && videoList.length === 0"></el-empty> -->
      </ul>
    </el-checkbox-group>
    <div class="pagination" v-show="total > pageSize">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-size="pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <CheckVideo v-if="videoSrc" @clearVideoSrc="clearVideoSrc" :videoSrc="videoSrc" />
  </div>
</template>

<script>
import EditName from './EditName.vue';
import CheckVideo from './CheckVideo.vue'
import { getStock } from '@/api/upload'

export default {
  name: "InteractVideo-List",
  data() {
    return {
      activeTab: "本地视频",
      model_id: undefined,
      loading: false,
      pageSize: 15,
      pageNum: 1,
      total: 0,
      keyword: '',
      selectLoading: false,
      stockArr: [], // 股票列表
      videoList: [], //互动视频列表
      visible: false,
      checkList: [], // 选中视频列表
      videoSrc: '',
      userInfo: JSON.parse(localStorage.getItem('userInfo')) || {}, // 获取localStorage中的info
    };
  },
  components: {
    EditName,
    CheckVideo
  },
  created() {
    this.getVideoList();
  },
  mounted() {
    // 接收批量上传接口上传成功后返回的值
    this.$bus.$on("uploadSuccess", async (from, data) => {
      if (from === 'material_video') {
        this.branchVideoCreate(data)
      }
    });
  },
  beforeDestroy () {
    this.$bus.$off('uploadSuccess');
  },
  methods: {
    checkBox(item) {
      // 当是编辑态的时候
      if (this.visible) {
        if (this.checkList.indexOf(item.id) === -1) {
          this.checkList.push(item.id)
        } else {
          this.checkList = this.checkList.filter(value => value !== item.id)
        }
      }
    },
    // 打开批量选中模式
    isEdit() {
      if (this.visible) {
        this.visible = false
        this.checkList = []
      } else {
        this.visible = true
      }
    },
    handleTabClick() {
      this.keyword = ''
      if (this.activeTab === '本地视频') {
        this.pageSize = 15
      } else {
        this.pageSize = 16
        switch (this.activeTab) {
          case '行情回顾':
            this.model_id = 9
            break;
          case '财务报告':
            this.model_id = 200
            break;
          case '舆情日报':
            this.model_id = 199
            break;
          case '个股诊断':
            this.model_id = 14
            break;
          case '产业链':
            this.model_id = 100
            break;
          default:
            this.model_id = undefined
            break;
        }
      }
      this.initData()
      this.getVideoList()
    },
    initData() {
      this.pageNum = 1
      this.total = 0
      this.videoList = []
      this.visible = false
      this.checkList = []
    },
    // 分页
    handleCurrentChange(val) {
        this.pageNum = val
        this.getVideoList()
    },
    // 本地视频搜索
    searchKeyword() {
      this.pageNum = 1;
      this.getVideoList()
    },
    changeValue() {
      this.pageNum = 1;
      this.getVideoList()
    },
    searchStock(value) {
      this.selectLoading = true
      getStock({
          key: value,
          type: 0
      }).then((res) => {
          this.selectLoading = false
          if (res && res instanceof Object && res.vStockItem && res.vStockItem.length > 0) {
              this.handleStockList(res.vStockItem)
          }
      })
    },
    handleStockList(value) {
      if (value && value instanceof Array && value.length > 0) {
          this.stockArr = value.map((item) => {
              return {
                  label: item.sCHNShortName,
                  value: item.sDtSecCode.slice(4),
              }
          })
      }
    },
    //获取列表
    async getVideoList() {
      this.loading = true
      try {
        if (this.activeTab === '本地视频') {
          let data = await this.API.materialCenter.branchVideoList({
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            videoName: this.keyword || undefined
          });
          this.videoList = data.data.list;
          this.total = data.data.total;
        } else {
          let data = await this.API.materialCenter.videoresultsearch({
            page: this.pageNum,
            size: this.pageSize,
            model_id: this.model_id,
            keyword: this.keyword || undefined
          })
          this.videoList = data.data.map((item) => {
            return {
              picUrl: item.video_image_url,
              videoName: item.stock_name || item.article_title,
              videoUrl: item.article_content
            }
          })
          this.total = data.counts
        }
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    //视频列表-删除
    del(item) {
      this.$confirm(`请确定是否${Array.isArray(item) ? '批量' : ''}删除分支视频?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        let params = []
        if (item && item instanceof Object) {
          params = [item.id]
        }
        if (item && item instanceof Array) {
          params = [...item]
        }
        const data = await this.API.materialCenter.branchVideoDelete(params);
        if (data.code == 200) {
          this.$message.success(`${Array.isArray(item) ? '批量' : ''}删除成功`);
          // 当前处于最后一页，并且最后一页只剩下最后一条数据被删除的时候,需要将pageNumber减一
          if (Math.ceil((this.total - 1) / this.pageSize) === this.pageNum || this.pageNum === 1) {
            this.getVideoList()
          } else {
            this.pageNum -= 1
            this.getVideoList()
          }
        } else {
          this.$message.error("删除失败" + JSON.stringify(data));
        }
      });
    },
    // 上传视频
    openUploadModal() {
      if (this.userInfo.remainTime > 0 || !this.userInfo.whiteStatus) {
        this.$bus.$emit('openUploadFile', {from:'material_video',type:'video'})
      }
    },
    // 调用上传视频接口
    async branchVideoCreate(value) {
      this.loading = true
      const params = value.map((item) => {
        return {
          smallPicUrl: item.smallPicUrl, // 可以不传，暂时没意义
          picUrl: item.picUrl,
          videoName: item.fileName,
          videoUrl: item.downUrl
        }
      })
      const data = await this.API.materialCenter.branchVideoCreate(params);
      if (data && data instanceof Object && data.code === 200) {
        this.getVideoList()
      }
    },
    checkVideo(value) {
      this.videoSrc = value.videoUrl
    },
    clearVideoSrc() {
      this.videoSrc = ''
    }
  },
};
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
