<template>
  <div :class="{ is_dragging: isDragging }" class="vote_page">
    <div class="content">
      <div class="hori_resizer" id="vote_horiResizer" :class="{ is_open: isDragerOpen, is_fullScreen: isFullScreen }">
        <el-tooltip content="隐藏编辑器" placement="top" v-if="!isFullScreen">
          <img :src="expansion2" @click="expansion" style="width:42px;" />
        </el-tooltip>
        <el-tooltip content="显示编辑器" placement="top" v-else>
          <img :src="expansion1" @click="expansion" style="left:-50px;width:42px;" />
        </el-tooltip>
      </div>
      <aside class="siderVote siderVote2" :style="'margin-left:' + (isSiderOpen ? 0 : currentLeft * -1) + 'px'">
        <div class="side_vote_material" :style="'transform:translateX(' + (isSiderOpen ? 0 : currentLeft * -1) + 'px)'">
          <div class="iframe_layer" v-show="isDragging"></div>
          <VoteList ref="voteList" />
        </div>
      </aside>
      <VoteEdit  ref="voteEdit"/>
    </div>
  </div>
</template>

<script>
import VoteList from "./voteList.vue";
import VoteEdit from "./voteEdit.vue";
import $ from "jquery";
export default {
  name: "Workbench-Home",
  components: {
    VoteList,
    VoteEdit
  },
  // 向下抛出父组件
  provide() {
    return { Instance: this };
  },
  data() {
    return {
      isSiderOpen: true, //侧栏宽度默认展开
      isDragerOpen: true, //侧栏宽度默认展开

      isDragging: false, //拖动侧栏时，需要让iframe覆盖一层div，避免拖动bug
      currentLeft: 1190, //从左0位置往右多少像素
      siderVoteLeft: 0, // 侧栏位置

      isFullScreen: false, // 全屏
      expansion1: require("@/assets/images/收缩.png"),
      expansion2: require("@/assets/images/展开.png"),
      currentTab: "我的名片"
    };
  },
  methods: {
    //左右拖动布局
    dragControllerDiv: function() {
      var that = this;
      var resizer = $("#vote_horiResizer")[0];
      resizer.onmousedown = function(e) {
        if (that.isFullScreen) return;
        that.isDragging = true;
        var startX = e.clientX;
        var originLeft = resizer.offsetLeft;
        // 鼠标拖动事件
        document.onmousemove = function(e) {
          var endX = e.clientX;
          var moveLen = endX - startX;
          that.currentLeft = originLeft + moveLen;

          const maxLeft = document.documentElement.clientWidth - 450 - 50; //从最左边算起的最大宽度，减去编辑器的最小宽度，和一个50的冗余
          const minLeft = 80 + 330; //从最左边算起的最小宽度
          if (that.currentLeft > maxLeft) {
            that.currentLeft = maxLeft;
          }
          if (that.currentLeft < minLeft) {
            that.currentLeft = minLeft;
          }
          resizer.style.left = that.currentLeft + 10 + "px"; // 设置左侧区域的宽度
          $(".siderVote2,.side_vote_material").css({
            width: that.currentLeft - 100 + "px"
          });
        };
        // 鼠标松开事件
        document.onmouseup = function(evt) {
          document.onmousemove = null;
          document.onmouseup = null;
          resizer.releaseCapture && resizer.releaseCapture(); //当你不在需要继续获得鼠标消息就要应该调用ReleaseCapture()释放掉
          that.isDragging = false;
        };
        resizer.setCapture && resizer.setCapture(); //该函数在属于当前线程的指定窗口里设置鼠标捕获
        return false;
      };
    },
    expansion() {
      this.fullScreen();
    },
    fullScreen() {
      this.isFullScreen = !this.isFullScreen;

      if (this.isFullScreen) {
        this.siderVoteLeft = this.currentLeft;

        const maxLeft = document.documentElement.clientWidth; //从最左边算起的最大宽度，减去编辑器的最小宽度，和一个50的冗余
        this.currentLeft = maxLeft;

        const resizer = $("#vote_horiResizer")[0];
        resizer.style.left = this.currentLeft + "px"; // 设置左侧区域的宽度
        $(".siderVote2,.side_vote_material").css({
          width: this.currentLeft - 100 - 60 + "px"
        });
      } else {
        this.currentLeft = this.siderVoteLeft;
        const resizer = $("#vote_horiResizer")[0];
        resizer.style.left = this.currentLeft + "px"; // 设置左侧区域的宽度
        $(".siderVote2,.side_vote_material").css({
          width: this.currentLeft - 100 - 10 + "px"
        });
      }
    },
  },
  mounted() {
    //左右拖动布局
    this.dragControllerDiv();
  }
};
</script>

<style lang="less" scoped>
.vote_page {
  width: calc(100vw - 134px);
  overflow: hidden;
}
.content {
    height: 100%;
    display: flex;
    flex-basis: auto;
    flex-direction: row;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
}


.siderVote {
    flex-shrink: 0;
    box-sizing: border-box;
    position: relative;
    transition: all .3s;
}

.siderVote2 {
    width: 1060px;
    z-index: 30;
}


.is_dragging .siderVote,
.is_dragging .side_vote_material,
.is_dragging .hori_resizer {
    transition: none;
}

.hori_resizer {
    position: fixed;
    z-index: 888;
    left: 1190px;
    margin-left: 15px;
    top: 124px;
    bottom: 0;
    width: 5px;
    background: #e6e9f0;
    cursor: e-resize;
    border-right: 1px solid #d5d7dd;
    border-left: 1px solid #fff;
    display: none;
}

.hori_resizer.is_open {
    display: block;
}

.hori_resizer img {
    top: 50%;
    right: -20px;
    position: absolute;
    cursor: pointer;
}

.iframe_layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    bottom: 0;
    z-index: 88;
}
</style>
